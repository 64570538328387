import React from "react"
import Layout from "../components/Layout"
import Word from "../components/RevealingWord"
import Seo from "../components/Seo"
import Form from "../components/ContactForm"
const Contact = () => {
  return (
    <Layout>
      <Seo title={`Kubo | Contact`} isArticle={false} />
      <section className="contactHeader">
        <div className="contactHeader-title">
          <h2 className="pageHeadings text-green">
            <Word
              word={`Need assistance with a website? We are Available for Hire.`}
            />
          </h2>
        </div>

        <div className="contactHeader-social">
          <a
            className="pageLinks"
            href="https://www.facebook.com/kubowebagency"
          >
            fb
          </a>
          <a className="pageLinks" href="https://twitter.com/KuboWeb">
            tw
          </a>
          <a
            className="pageLinks"
            href="https://www.instagram.com/kubo_web_agency/"
          >
            in
          </a>
        </div>
      </section>

      <div className="contactArea">
        <div className="contactArea-steps">
          <div className="contactArea-steps--heading">
            <h3 className="">What will be our next step?</h3>
            <p>You will get one bit closer to build your ideal website.</p>
          </div>
          <ul className="contactArea-steps--timeline">
            <li className="timeline">
              <h4>You will get a proposal</h4>
              <p>
                Scope, timeline and approximate price will be incorporated if
                you provide detail information about the project.
              </p>
            </li>
            <li className="timeline">
              <h4>Together we will discuss</h4>
              <p>
                We will acclimate and examine all the potential variations and
                alternatives. Call or A video call usually works great.
              </p>
            </li>
            <li className="timeline last">
              <h4>Start Building</h4>
              <p>
                When all the objective and goals are set, We can start the first
                sprint.
              </p>
            </li>
          </ul>
          <div className="contactArea-steps--reachOut ">
            <p>
              Schedule a <span className="text-green">free</span> consultation
            </p>
            <p>
              e-mail at :
              <a className="text-green" href="mailto:contact@kubo.co.in">
                contact@kubo.co.in
              </a>
              <br />
              or
            </p>
            <p>
              <a href="tel:+91-9693943540" className="pageLinks">
                Make A call
              </a>
            </p>
          </div>
        </div>
        <div className="contactArea-form" id="contact-form">
          <Form />
          {/* <p className="form-heading">
            Write a couple of lines about your project, and We will set up a
            proposition for you within 24 hours.
          </p>
          <form action="">
            <p className="form-input">
              <label htmlFor="">Your name</label>
              <input type="text" />
            </p>
            <p className="form-input">
              <label htmlFor="">Your e-mail</label>
              <input type="email" />
            </p>
            <p className="form-input">
              <label htmlFor="">Your phone</label>
              <input type="phone" />
            </p>
            <p className="form-input">
              <label htmlFor="">Project details</label>
              <textarea name="" id=""></textarea>
            </p>
            <button className="formSend">SEND</button>
          </form> */}
        </div>
      </div>
    </Layout>
  )
}

export default Contact

import React, { useRef, useEffect } from "react"

const RevealingWord = props => {
  const word = props.word
  const wordArr = word.split(" ")

  const heading = useRef()

  useEffect(() => {
    //===============================================================================
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.6,
    }
    let callback = (entries, observer) => {
      entries.forEach(entry => {
        const hasClass = entry.target.classList.contains("fade-in")
        if (entry.isIntersecting && !hasClass) {
          entry.target.classList.add("fade-in")
        }
      })
    }
    let observer = new IntersectionObserver(callback, options)
    observer.observe(heading.current)
    //==================================================================================
    return () => {
      observer.unobserve(heading.current)
    }
  }, [])

  return (
    <span ref={heading} className="revealText-wrapper">
      {wordArr.map((letter, index) => {
        return (
          <span key={word + letter + index} style={{ ["--anim-delay"]: index }}>
            {letter}&nbsp;
          </span>
        )
      })}
    </span>
  )
}

export default RevealingWord

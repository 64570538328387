import React, { useState } from "react"
import { navigate } from "gatsby"
const ContactForm = () => {
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    subject: "New message",
  })

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  //.then(() => navigate("/thanks/"))
  const handleSubmit = e => {
    e.preventDefault()
    const loadingBtn = document.querySelector(".sendBtn-text")
    //loadingBtn.classList.add("active")
    loadingBtn.textContent = "SENDING..."
    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({
        "form-name": "contact",
        ...formState,
      }),
    })
      .then(() => {
        setTimeout(() => {
          loadingBtn.textContent = "DONE"
          const confirmationMsg = document.querySelector(".sendConfirmationMsg")
          confirmationMsg.style.transform = "translateX(0)"
          const resetInputArray = document.querySelectorAll(".form-input>*")
          resetInputArray.forEach(item => {
            item.value = ""
          })
          setTimeout(() => {
            confirmationMsg.style.transform = "translateX(110%)"
            loadingBtn.textContent = "SEND"
          }, 2500)
        }, 2800)
      })
      .catch(error => alert(error))
  }

  return (
    <>
      <p className="form-heading">
        Write a couple of lines about your project, and We will set up a
        proposition for you within 24 hours.
      </p>
      <form
        id="contactForm"
        data-netlify="true"
        name="contact"
        method="post"
        action="/"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="subject" value="New message" />
        <p className="form-input">
          <label htmlFor="name" name="name" aria-label="Name">
            Your name (required)
          </label>
          <input
            required
            type="text"
            name="name"
            onChange={handleChange}
            value={formState.name}
          />
        </p>
        <p className="form-input">
          <label
            htmlFor="email"
            name="email"
            className="formLabel"
            aria-label="Email"
          >
            Your e-mail (required)
          </label>
          <input
            required
            type="email"
            name="email"
            onChange={handleChange}
            value={formState.email}
          />
        </p>
        <p className="form-input">
          <label htmlFor="phone" name="phone" aria-label="Phone">
            Your phone (required)
          </label>
          <input
            required
            type="tel"
            name="phone"
            onChange={handleChange}
            value={formState.phone}
          />
        </p>
        <p className="form-input">
          <label htmlFor="message" name="message">
            Project details (optional)
          </label>
          <textarea
            name="message"
            onChange={handleChange}
            value={formState.message}
          ></textarea>
        </p>

        <button className="formSend">
          <span className="sendBtn-text">SEND</span>
        </button>
        <p className="sendConfirmationMsg">
          Thank You for Contacting us. We will get back to you soon.
        </p>
      </form>
    </>
  )
}

export default ContactForm
